<template>
  <div class="container main">
    <h3>Progetti:</h3>
      <div class="menu2 d-flex">
        <a
          v-for="(p,i) in getProj"
          :key="i"
          :href="p.url"
          :class="{'active': prog.url === p.url}"
          class="link-proj" >
          {{ p.titolo }}
        </a>
      </div>

    <div class="row">
      <div class="col-lg-6">
        <h1>{{ prog.titolo }}</h1> 
        <h2>{{ prog.sottotitolo }}</h2> 
        <div class="colophon" v-html="prog.colophon"></div> 
      </div>
      <div class="col-lg-6">
        <img class="img-fluid top-img" :src="require(`@/assets/images/progetti/${prog.top_img}`)" :alt="prog.titolo">
      </div>

    </div>
    <div class="row">
      <p class="descrizione" v-html="prog.descrizione"></p>
    </div>

    <div class="row team"
      v-for="(t,i) in prog.team"
      :key="i"
      >
      <div class="col-lg-6">
        <h3>{{ t.nome }}</h3>
        <h5>{{ t.ruolo }}</h5>
        <p v-html="t.bio"></p>
      </div>
      <div class="col-lg-6">
        <img class="team-img" :src="require(`@/assets/images/progetti/${t.img}`)" :alt="t.nome">
      </div>
    </div><!-- end team -->

    <div v-if="prog.audio" class="audio">
      <div class="row d-flex inner"
       v-for="(a,i) in prog.audio"
      :key="i+'a'" >
        <div class="col-sm-6">
          <p>{{ a.name }}</p>
        </div>
        <div class="col-sm-6">
          <audio controls >
            <source :src="require(`@/assets/audio/${a.src}`)" type="audio/mpeg">
          </audio>
        </div>
        <!-- <p class="col-6 ">{{ a.name }}</p>
        <audio controls class="col-6">
          <source :src="require(`@/assets/audio/${a.src}`)" type="audio/mpeg">
        </audio> -->
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Project',
  props:{
    progects:Array,
    lang:String,
    progects_en:Array,
    id:Number
  },
  computed: {
    getProj(){
      if(this.lang === 'en'){
        return this.progects_en;
      }
      return this.progects;
    },
    prog(){
      if(this.lang === 'en'){
        return this.progects_en[this.id]
      }
      return this.progects[this.id]
    }
  },
  data(){
    return{
    
    }
  }

}
</script>

<style lang="scss" scoped>

h1{
  font-size: 5rem;
  color: brown;
  font-weight: 700;
}

.colophon{
  font-size: 1.5rem;
}

.main{
  margin-top: 60px;
}

.menu2{
  margin-bottom: 20px;
  a{
    display: inline-block;
    text-decoration: none;
    padding: 10px;
    background-color: #a5a5a5;
    color: white;
    margin: 3px;
    border-radius: 5px;
    &.active, &:hover{
      background-color:brown
    }
  }
}

img{
  box-shadow: 0 10px 10px rgba(0,0,0,.2);
  border-radius: 10px;
  margin: 15px 0;
}

.descrizione{
  background-color:brown;
  color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 10px 10px rgba(0,0,0,.2);
  font-weight: 500;
  margin: 20px 0;
}

.team{
  margin-bottom: 30px;
  background-color: #ddd;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 10px 10px rgba(0,0,0,.2);
  
}
.team{
  h3{
    color: brown;
  }
  img{
    width: 100%;
  }
}
.audio{
  
  line-height: 54px;
  font-size: 1.5rem;
  .inner{
    width: 100%;
  }
}

</style>